import { Injectable } from "@angular/core"
import { FindUserDto, ResultDto } from "@api/dto"

import { HttpService } from "./http.service"

import { AdminExportByMallOrderDto, AdminExportDiscountCampaign, AdminExportMallOrderDto, AdminExportTenantOrderDto, AdminExportVoucherDto } from "../dto/admin-report.dto"

@Injectable()
export class AdminReportService {
    constructor(
        public httpService: HttpService
    ) {}

  async exportMallOrder(mallOrder: AdminExportMallOrderDto) {
    return await this.httpService.get<ResultDto>(`/admin/v1/mall-orders/export`, mallOrder)
  }
    
  async exportOrderByMall(mallOrder: AdminExportByMallOrderDto) {
    return await this.httpService.get<ResultDto>(`/admin/v1/mall-orders/export-report`, mallOrder)
  }

  async exportByTenantOrder(tenantOrder: AdminExportTenantOrderDto) {
    return await this.httpService.get<ResultDto>(`/admin/v1/tenant-orders/export-report`, tenantOrder)
  }

  async exportFinancialOrderByMall(mallOrder: AdminExportByMallOrderDto) {
    return await this.httpService.get<ResultDto>(`/admin/v1/mall-orders/export-financial-report`, mallOrder)
  }

  async exportFinancialByTenantOrder(tenantOrder: AdminExportTenantOrderDto) {
    return await this.httpService.get<ResultDto>(`/admin/v1/tenant-orders/export-financial-report`, tenantOrder)
  }

  async exportProductSaleOrder(adminExportTenantOrderDto: AdminExportTenantOrderDto) {
    return await this.httpService.get<ResultDto>(`/admin/v1/tenant-orders/export-product-sale-report`, adminExportTenantOrderDto)
  }

  async exportProductSaleOrderByTransactionDate(adminExportTenantOrderDto: AdminExportTenantOrderDto) {
    return await this.httpService.get<ResultDto>(`/admin/v1/tenant-orders/export-product-sale-report-by-transaction-date`, adminExportTenantOrderDto)
  }

  async exportFlashSale(adminExportTenantOrderDto: AdminExportTenantOrderDto) {
    return await this.httpService.get<ResultDto>(`/admin/v1/flash-sale/export`, adminExportTenantOrderDto)
  }

  async exportCustomer(findUserDto: FindUserDto) {
    return await this.httpService.get<ResultDto>(`/admin/v1/users/export`, findUserDto)
  }

  async exportDiscountCampaign(discountCamoaign: AdminExportDiscountCampaign) {
    return await this.httpService.get<ResultDto>(`/admin/v1/products-discount/export-report`, discountCamoaign)
  }

  async exportVoucher(voucherOrder: AdminExportVoucherDto) {
    return await this.httpService.get<ResultDto>(`/admin/v1/mall-vouchers/export-history`, voucherOrder)
  }

  async exportCoupon(voucherOrder: AdminExportVoucherDto) {
    return await this.httpService.get<ResultDto>(`/admin/v1/mall-coupons/export-history`, voucherOrder)
  }
}